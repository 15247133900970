import { PackagesNameTracking, packagesNaming } from "domain/Preview/ComposePreview/Packages/utils/packages-naming";
import type {
  CheckoutStoreTrackingData,
  RecordCheckTrackingData,
  ReportPricingTrackingData,
} from "./dataLayerECommerceEvents.types";
import { dataLayerUtil } from "./utils/dataLayerUtil";
import { vatUtil } from "./utils/vatUtil";
import { pushToDataLayer } from "./utils/pushToDataLayer";

const submitViewItemEvent = (
  itemName: string,
  currency: string,
  priceIncludingTax: number,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const {
    reportDataTotal = undefined,
    vinreg = undefined,
    reportCombination = undefined,
    vinregSearchType = undefined,
  } = recordCheckTrackingData;

  const eventParameters = {
    event: "view_item",
    ecommerce: {
      currency: currency,
      value: priceIncludingTax,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: priceIncludingTax,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
          item_category2: vinregSearchType,
        },
      ],
    },
  };
  pushToDataLayer({ ecommerce: null });
  pushToDataLayer(eventParameters);
};

const submitAddToCartEvent = (
  itemName: string,
  currency: string,
  priceIncludingTax: number,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const {
    reportDataTotal = undefined,
    vinreg = undefined,
    reportCombination = undefined,
    vinregSearchType = undefined,
  } = recordCheckTrackingData;

  const eventParameters = {
    event: "add_to_cart",
    ecommerce: {
      currency: currency,
      value: priceIncludingTax,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: priceIncludingTax,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
          item_category2: vinregSearchType,
        },
      ],
    },
  };
  pushToDataLayer({ ecommerce: null });
  pushToDataLayer(eventParameters);
};

const submitBeginCheckoutEvent = (
  checkoutStoreTrackingData: CheckoutStoreTrackingData,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const { currency, price, itemName } = checkoutStoreTrackingData;
  const {
    reportDataTotal = undefined,
    vinreg = undefined,
    reportCombination = undefined,
    vinregSearchType = undefined,
  } = recordCheckTrackingData;

  const eventParameters = {
    event: "begin_checkout",
    ecommerce: {
      currency: currency,
      value: price,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: price,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
          item_category2: vinregSearchType,
        },
      ],
    },
  };
  pushToDataLayer({ ecommerce: null });
  pushToDataLayer(eventParameters);
};

const submitAddPaymentInfoEvent = (
  paymentType: string,
  checkoutStoreTrackingData: CheckoutStoreTrackingData,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const { currency, price, itemName } = checkoutStoreTrackingData;
  const {
    reportDataTotal = undefined,
    vinreg = undefined,
    reportCombination = undefined,
    vinregSearchType = undefined,
  } = recordCheckTrackingData;

  const eventParameters = {
    event: "add_payment_info",
    ecommerce: {
      currency: currency,
      value: price,
      payment_type: paymentType,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: price,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
          item_category2: vinregSearchType,
        },
      ],
    },
  };
  pushToDataLayer({ ecommerce: null });
  pushToDataLayer(eventParameters);
};

const submitPurchaseEvent = (
  lang: string,
  checkoutTrackingData: CheckoutStoreTrackingData,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const {
    reportDataTotal = undefined,
    vinreg = undefined,
    reportCombination = undefined,
    isReactivated = "no",
    vinregSearchType = undefined,
  } = recordCheckTrackingData;
  const { currency, price, itemName, transactionId, paymentType, coupon = undefined } = checkoutTrackingData;

  const eventParameters = {
    event: "purchase",
    ecommerce: {
      transaction_id: transactionId,
      currency: currency,
      payment_type: paymentType,
      coupon: coupon,
      tax: vatUtil.calculateVat(lang, price),
      is_reactivated: isReactivated,
      value: price,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: price,
          is_reactivated: isReactivated,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
          item_category2: vinregSearchType,
        },
      ],
    },
  };
  pushToDataLayer({ ecommerce: null });
  pushToDataLayer(eventParameters);
};

const submitCreditPurchaseEvent = (
  reportPricingTrackingData: ReportPricingTrackingData,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const { isReactivated = "no" } = recordCheckTrackingData;
  const { currency, totalAmount } = reportPricingTrackingData;

  const eventParameters = {
    event: "purchase_with_credits",
    ecommerce: {
      package_name: PackagesNameTracking.Single,
      currency: currency,
      payment_type: "credits",
      is_reactivated: isReactivated,
      value: totalAmount,
    },
  };
  pushToDataLayer({ ecommerce: null });
  pushToDataLayer(eventParameters);
};

export const dataLayerECommerceEvents = {
  submitViewItemEvent,
  submitAddToCartEvent,
  submitBeginCheckoutEvent,
  submitAddPaymentInfoEvent,
  submitPurchaseEvent,
  submitCreditPurchaseEvent,
};
