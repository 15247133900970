import { css } from "@emotion/react";
import { mediaQuery, spacing, colors } from "../../core/styles";

export const styles = {
  contentWrapper: (isShort: boolean, isAnimating: boolean) =>
    css({
      maxWidth: isShort ? "436px" : "712px",
      width: `calc(100% - ${spacing.baseUnitMobile2} * 2)`,
      maxHeight: `calc(100% - ${spacing.baseUnitMobile2} * 2)`,
      overflow: "auto",
      padding: isShort ? `${spacing.baseUnitMobile2}` : `${spacing.baseUnitMobile5} ${spacing.baseUnitMobile2}`,
      opacity: isAnimating ? 1 : 0,
      transition: "opacity 0.25s ease-in-out",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      zIndex: 999,
      [mediaQuery.tabletOrLarger]: {
        padding: isShort ? `${spacing.baseUnitMobile2}` : `${spacing.baseUnitMobile5} ${spacing.baseUnitMobile3}`,
      },
    }),
  closeButtonWrapper: () =>
    css({
      position: "absolute",
      right: spacing.baseUnitMobile2,
      cursor: "pointer",
      boxSizing: "content-box",
      button: {
        padding: 0,
        "&:hover": {
          background: "initial",
          boxShadow: "none",
        },
        "&:focus": {
          background: "initial",
          boxShadow: "none",
        },
      },
    }),
  IconButton: () =>
    css({
      position: "absolute",
      top: "4px",
      right: "4px",
      padding: "12px",
      cursor: "pointer",
      boxSizing: "content-box",
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      "&&: focus": {
        outline: `solid 2px ${colors.linkBlue.hex}`,
      },
      "&&: hover": {
        backgroundColor: colors.splitterGrey.hex,
      },
      "&&: active": {
        backgroundColor: colors.backgroundGrey.hex,
      },
    }),
};
