import React, { useState, useEffect, useRef } from "react";
import { Button, ButtonVariants } from "../../elements/Button";
import { Icon, Icons } from "../../elements/Icon";
import { styles } from "./Modal.style";
import { BackShadow } from "../../elements/BackShadow";

export interface ModalProps {
  isOpen: boolean;
  onClose: React.MouseEventHandler;
  closeText?: string;
  children?: React.ReactNode;
  isShort?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, closeText, isShort, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const timerRef = useRef<number | null>(null);
  const firstFocusableElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      if (firstFocusableElementRef.current) {
        firstFocusableElementRef.current.focus();
      }
    } else {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [isOpen]);

  const closeModal = (e) => {
    setIsAnimating(false);
    if (isOpen) {
      timerRef.current = window.setTimeout(() => {
        onClose(e);
      }, 250);
    } else {
      onClose(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      closeModal(e);
    }
  };

  return (
    <>
      {isOpen && (
        <div onClick={closeModal}>
          <div css={styles.contentWrapper(isShort, isAnimating)} onClick={(e) => e.stopPropagation()}>
            <div
              css={styles.IconButton}
              onClick={closeModal}
              onKeyDown={handleKeyDown}
              ref={firstFocusableElementRef}
              tabIndex={0}
            >
              <Icon icon={Icons.closeBlue} />
            </div>
            {children}
            {!!closeText && (
              <div css={styles.closeButtonWrapper()} tabIndex={0} onKeyDown={handleKeyDown}>
                <Button variant={ButtonVariants.TextDecorated} onClick={closeModal}>
                  {closeText}
                </Button>
              </div>
            )}
          </div>
          <BackShadow isBehindModal={true} />
        </div>
      )}
    </>
  );
};
